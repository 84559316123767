const { registerMicroApps, start, setDefaultMountApp } = require('qiankun')

registerMicroApps([
  {
    name: 'sso_login',
    entry: process.env.MICRO_APP_SSO_URL,
    container: '#container',
    activeRule: '/login',
  },
  {
    name: 'sso_logout',
    entry: process.env.MICRO_APP_SSO_URL,
    container: '#container',
    activeRule: '/logout',
  },
  {
    name: 'sso_oauth2',
    entry: process.env.MICRO_APP_SSO_URL,
    container: '#container',
    activeRule: '/oauth2',
  },
  {
    name: 'dashboard',
    entry: process.env.MICRO_APP_DASHBOARD_URL,
    container: '#container',
    activeRule: '/dashboard',
  },
  {
    name: 'personbill',
    entry: process.env.MICRO_APP_PERSONBILL_URL,
    container: '#container',
    activeRule: '/personbill',
  },
  {
    name: 'cartoon',
    entry: process.env.MICRO_APP_CARTOON_URL,
    container: '#container',
    activeRule: '/cartoon',
  },
]);

setDefaultMountApp('/dashboard');

// 启动 qiankun
start();